<template>
  <div class="cust">
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.setting_bonus") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <el-row :gutter="20" class="m-1">
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <el-input
            placeholder="Поиск"
            ref="search1"
            class="my-kh-search"
            size="small"
            v-model="search1"
          ></el-input>
          <el-tree
            class="filter-tree"
            :data="list"
            show-checkbox
            node-key="id"
            @check-change="selectedItem"
            :filter-node-method="filterNode"
            ref="items"
            :expand-on-click-node="true"
          >
            <span
              style="line-height: 150px"
              class="custom-tree-node"
              slot-scope="{ node, data }"
            >
              <span class="aaa my-tree-khan m-05">{{
                selectedType == "doctor"
                  ? data.surname + " " + data.name
                  : data.name
              }}</span>
            </span>
          </el-tree>
        </el-col>
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <el-input
            placeholder="Поиск"
            ref="search2"
            class="my-kh-search"
            size="small"
            v-model="search2"
          ></el-input>
          <div class="block">
            <el-tree
              class="filter-tree"
              :data="service"
              show-checkbox
              node-key="id"
              @check-change="selectedService"
              :filter-node-method="filterNode"
              ref="services"
              :expand-on-click-node="true"
            >
              <span
                style="line-height: 150px"
                class="custom-tree-node"
                slot-scope="{ node, data }"
              >
                <span class="aaa my-tree-khan m-05">
                  <div class="w-200">{{ data.name }}</div>
                  <el-select
                    v-if="selectedType != 'partner_clinic'"
                    :placeholder="$t('message.class')"
                    v-model="data.amount_type"
                    :disabled="!node.checked"
                    @change="changeParentType(data)"
                    size="small"
                    class="my-kh ml-3"
                    style=""
                  >
                    <el-option
                      v-for="item in classes"
                      :key="item.name"
                      :label="item.name"
                      :value="item.type"
                    >
                    </el-option>
                  </el-select>
                  <crm-input
                    :disabled="
                      !data.amount_type && selectedType != 'partner_clinic'
                    "
                    @input="changeParentAmount(data)"
                    v-model="data.amount"
                    :placeholder="'sum'"
                    :ref="'data_amount' + node.id"
                    class="my-kh"
                    size="small"
                  ></crm-input>
                </span>
              </span>
            </el-tree>
          </div> </el-col
      ></el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";

export default {
  mixins: [form],
  props: ["selectedType"],
  data() {
    return {
      search1: "",
      search2: "",
      selectedServices: [],
      selectedList: [],
      classes: [
        {
          type: "percent",
          name: "Процент",
        },
        {
          type: "amount",
          name: "Сумма",
        },
      ],
    };
  },
  created() {},
  async mounted() {},
  computed: {
    ...mapGetters({
      list: "bonusService/list",
      service: "bonusService/services",
    }),
  },
  watch: {
    search1(val) {
      this.$refs.items.filter(val);
    },
    search2(val) {
      this.$refs.services.filter(val);
    },
  },
  methods: {
    ...mapActions({
      store: "bonusService/store",
    }),
    selectedService() {
      this.selectedServices = [];
      let item = this.$refs.services.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0) {
            this.selectedServices.push(element);
          }
        }
      }
    },
    changeParentType(data) {
      data.children.map((i) => {
        i.amount_type = data.amount_type;
      });
    },
    changeParentAmount(data) {
      data.children.map((i) => {
        i.amount = data.amount;
      });
    },
    selectedItem() {
      this.selectedList = [];
      let item = this.$refs.items.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          this.selectedList.push(element);
        }
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    parent() {
      return this.$parent.$parent;
    },
    close() {
      this.parent().closeModal(this.drawerName);
    },
    submit(close = true) {
      let data = {
        items: JSON.parse(JSON.stringify(this.selectedList)),
        services: JSON.parse(JSON.stringify(this.selectedServices)),
        type:
          this.selectedType == "partner_clinic" ? "amount" : this.selectedType,
      };

      this.store(data)
        .then((res) => {
          this.$alert(res);
          this.$refs.items.setCheckedNodes([]);
          this.$refs.items.setCheckedKeys([]);
          this.$refs.services.setCheckedNodes([]);
          this.$refs.services.setCheckedKeys([]);
          this.close();
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};
</script>
<style>
.cust {
  overflow: hidden;
}
.my-kh input,
.my-kh {
  width: 200px !important;
  /* height: 32px  !important;
  line-height: 32px  !important; */
  margin-right: 7px !important;
  padding-right: 7px !important;
  display: block !important;
}
.filter-tree .custom-tree-node {
  width: 95%;
  display: inline-table !important;
}

.cust .filter-tree .el-tree-node__content .aaa {
  display: flex;
}
.cust .filter-tree .el-tree-node__content {
  height: 40px;
}
.cust .el-tree-node__content {
  overflow: visible;
}
.cust .w-200 {
  width: 200px;
  height: auto;
  line-height: 160%;
}
.cust .filter-tree .el-tree-node__content {
  height: auto;
  min-width: 700px;
}

.cust .m-05 {
  margin: 0.4rem !important;
}

.cust .m-1 {
  margin: 1rem !important;
}

.my-kh-search {
  width: 100%;
  margin: 1rem 0;
}
</style>