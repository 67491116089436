<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100"></div>
    </div>

    <div class="big-green-btn-wrap">
      <el-button
        type="success"
        size="medium"
        class="ml-4 big-green-btn"
        @click="openDrawer('doctor')"
        style="width: 20%"
        :loading="loadingButton"
        :disabled="loadingButton ? true : false"
      >
        <h4>{{ $t("message.doctor") }}</h4>
      </el-button>

      <el-button
        type="success"
        size="medium"
        class="ml-4 big-green-btn"
        @click="openDrawer('partner_doctor')"
        style="width: 20%"
        :loading="loadingButton2"
        :disabled="loadingButton2 ? true : false"
      >
        <h4>{{ $t("message.partner_doctor") }}</h4>
      </el-button>

      <el-button
        type="success"
        size="medium"
        class="ml-4 big-green-btn"
        @click="openDrawer('partner_clinic')"
        style="width: 20%"
        :loading="loadingButton3"
        :disabled="loadingButton3 ? true : false"
      >
        <h4>{{ $t("message.partner_clinic") }}</h4>
      </el-button>
    </div>

    <el-drawer
      title="Новый тег"
      :visible.sync="drawerCreate"
      size="80%"
      :drawer="drawerCreate"
    >
      <div>
        <crm-create :selectedType="selectedType"></crm-create>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

import CrmCreate from "./components/crm-create";
export default {
  components: {
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      data: null,
      selectedType: null,
      loadingButton: false,
      loadingButton2: false,
      loadingButton3: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      getDoctor: "bonusService/getDoctor",
      getPartnerClinic: "bonusService/getPartnerClinic",
      getPartnerDoctor: "bonusService/getPartnerDoctor",
    }),
    closeModal(drawer) {
      this.drawerCreate = false;
    },
    openDrawer(type) {
      this.selectedType = type;
      if (type == "partner_doctor") {
        this.getPartnerDoctor().finally((res) => {
          this.drawerCreate = true;
        });
      }
      if (type == "partner_clinic") {
        this.getPartnerClinic().finally((res) => {
          this.drawerCreate = true;
        });
      }
      if (type == "doctor") {
        this.getDoctor().finally((res) => {
          this.drawerCreate = true;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.big-green-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 75vh;
  width: 100%;
}

.big-green-btn {
  height: 250px;
  width: 250px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.el-icon-download:before {
  font-size: 60px;
}
.el-icon-user:before {
  font-size: 60px;
}
.el-icon-users:before {
  font-size: 60px;
}
</style>